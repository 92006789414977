import { Checkbox } from '@mui/joy';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import Option from '@mui/joy/Option';
import Select from '@mui/joy/Select';
import Stack from '@mui/joy/Stack';
import { Typography } from '@mui/material';
import useFilterStore from '../stores/filter';

const options: Array<{ key: number; label: number }> = [
  { key: 2020, label: 2020 },
  { key: 2021, label: 2021 },
  { key: 2022, label: 2022 },
  { key: 2023, label: 2023 },
  { key: 2024, label: 2024 },
  { key: 2025, label: 2025 },
].sort((a, b) => b.key - a.key);

export type Range = {
  yearStart: number;
  yearEnd: number;
  monthStart: number;
  monthEnd: number;
};

export const RangeSelect = () => {
  const { range, monthOptions, setRange, enableRangeSelect, setEnableRangeSelect } = useFilterStore();

  if (!enableRangeSelect) {
    return (
      <Stack gap={2} direction={'row'}>
        <FormControl>
          <FormLabel>Jaar</FormLabel>
          <Select
            sx={{ minWidth: '12rem' }}
            onChange={(_, newValue) => {
              if (!newValue) {
                console.error(`Year ${newValue} not found`);
                return;
              }
              setRange({ ...range, yearStart: newValue, yearEnd: newValue });
            }}
            value={range.yearStart}
          >
            {options.map((user) => {
              return (
                <Option key={user.key} value={user.key}>
                  {user.label}
                </Option>
              );
            })}
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel>Aangepaste periode</FormLabel>
          <Checkbox
            label="Aan/uit"
            onClick={() => {
              setEnableRangeSelect(!enableRangeSelect);
            }}
            checked={enableRangeSelect}
          />
        </FormControl>
      </Stack>
    );
  }

  return (
    <Stack direction={'row'} gap={3}>
      <Stack direction={'column'} gap={1}>
        <Stack direction={'row'} gap={1}>
          <FormControl>
            <FormLabel>Aangepaste periode</FormLabel>
            <Select
              sx={{ minWidth: '12rem' }}
              onChange={(_, newValue) => {
                if (!newValue) {
                  console.error(`Year ${newValue} not found`);
                  return;
                }
                setRange({ ...range, yearStart: newValue });
              }}
              value={range.yearStart}
              startDecorator={<Typography>Van:</Typography>}
            >
              {options.map((user) => {
                return (
                  <Option key={user.key} value={user.key}>
                    {user.label}
                  </Option>
                );
              })}
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel></FormLabel>
            <Select
              defaultValue={range.monthStart}
              sx={{ width: '5rem', marginTop: '1.3rem' }}
              onChange={(_, option) => {
                if (option === null) return;
                setRange({
                  monthEnd: range.monthEnd,
                  monthStart: option,
                  yearEnd: range.yearEnd,
                  yearStart: range.yearStart,
                });
              }}
            >
              {monthOptions.map((option) => (
                <Option
                  key={option.key}
                  value={option.key}
                  disabled={Number.parseInt(option.key as string) > range.monthEnd && range.yearStart === range.yearEnd}
                >
                  {option.label}
                </Option>
              ))}
            </Select>
          </FormControl>
        </Stack>

        <Stack direction={'row'} gap={1}>
          <FormControl>
            <FormLabel></FormLabel>
            <Select
              sx={{ minWidth: '12rem' }}
              onChange={(_, newValue) => {
                if (!newValue) {
                  console.error(`Year ${newValue} not found`);
                  return;
                }
                setRange({ ...range, yearEnd: newValue });
              }}
              startDecorator={<Typography>Tot:</Typography>}
              value={range.yearEnd}
            >
              {options.map((user) => {
                return (
                  <Option key={user.key} value={user.key}>
                    {user.label}
                  </Option>
                );
              })}
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel></FormLabel>
            <Select
              sx={{ width: '5rem' }}
              defaultValue={range.monthEnd}
              onChange={(_, option) => {
                if (option === null) return;
                setRange({
                  monthEnd: option,
                  monthStart: range.monthStart,
                  yearEnd: range.yearEnd,
                  yearStart: range.yearStart,
                });
              }}
            >
              {monthOptions.map((option) => (
                <Option
                  key={option.key}
                  value={option.key}
                  disabled={Number.parseInt(option.key as string) < range.monthStart && range.yearStart === range.yearEnd}
                >
                  {option.label}
                </Option>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </Stack>
      <FormControl>
        <FormLabel>Aangepaste periode</FormLabel>
        <Checkbox
          label="Aan/uit"
          onClick={() => {
            setEnableRangeSelect(!enableRangeSelect);
          }}
          checked={enableRangeSelect}
        />
      </FormControl>
    </Stack>
  );
};
