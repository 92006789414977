import client from '../../../utils/axiosClient';

export type PaymentTerm = {
  account: string;
  accountName: string;
  journal: string;
  journalDescription: string;
  invoiceDate: string;
  entryNumber: string;
  description: string;
  transactionAmountDC: number;
  created: Date;
  modified: Date;
  id: string;
};

type ManualPaymentTermsByAccount = {
  account: string;
  accountName: string;
  manualPaymentTerms: PaymentTerm[];
};

export type GetPaymentTermsAutomationByAccountParams = { year: number; administrationId: string };

export const getPaymentTermsAutomationByAccount = async (params: GetPaymentTermsAutomationByAccountParams) => {
  const response = await client.get<ManualPaymentTermsByAccount[]>(`/api/paymentTerms/automation/${params.administrationId}`, {
    params: {
      year: params.year,
    },
  });

  return response.data;
};
