import BoltIcon from '@mui/icons-material/Bolt';
import BubbleChartRoundedIcon from '@mui/icons-material/BubbleChartRounded';
import DashboardCustomizeRoundedIcon from '@mui/icons-material/DashboardCustomizeRounded';
import HandshakeRoundedIcon from '@mui/icons-material/HandshakeRounded';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import LibraryAddCheckRoundedIcon from '@mui/icons-material/LibraryAddCheckRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import { Box, ListItemDecorator, Tab, TabList, TabPanel, Tabs } from '@mui/joy';
import Stack from '@mui/joy/Stack';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { PageHeader } from '../../components/pageHeader';
import useDivision from '../../hooks/useDivision';
import TranslationKeys from '../../translations/keys';
import CompletenessDrilldown from '../completeness-drilldown';
import { DataViz } from '../dataViz';
import InsightsView from '../insights-drilldown/index';
import KiaTransactions from '../kia-transactions';
import { AdministrationActions } from './actions';
import { GeneralAdministrationInfo } from './general/index';
import EfficiencyAutomationDrilldown from './efficiency/automation';

export const AdministrationDetails = () => {
  const { t } = useTranslation();
  const params = useParams();
  const { divisionId, tab } = params;
  const divisionQuery = useDivision(divisionId as string);
  const navigate = useNavigate();

  const changeTab = (tab: string) => {
    let path = window.location.pathname;
    let pathArray = path.split('/');
    pathArray[pathArray.length - 1] = tab; // Change the last parameter
    const newPath = pathArray.join('/');

    navigate(newPath); // Use { replace: true } if you want to replace the current entry
  };

  const division: any = divisionQuery.data || {};

  return (
    <Stack direction={'column'} gap={3}>
      <PageHeader>{`${t(TranslationKeys.administrations)} - ${division.Description}`}</PageHeader>

      <Tabs
        value={tab}
        onChange={(_, newTab) => {
          if (!newTab) return;
          changeTab(newTab as string);
        }}
        sx={{
          borderRadius: 'sm',
          boxShadow: 'sm',
          overflow: 'auto',
        }}
      >
        <TabList sx={{ py: '.5rem' }}>
          <Tab variant="plain" color="neutral" value={'general'}>
            <ListItemDecorator>
              <InfoRoundedIcon />
            </ListItemDecorator>
            Algemeen
          </Tab>
          <Tab variant="plain" color="neutral" value={'efficiency'}>
            <ListItemDecorator>
              <BoltIcon />
            </ListItemDecorator>
            Efficiëntie
          </Tab>
          <Tab variant="plain" color="neutral" value={'quality'}>
            <ListItemDecorator>
              <StarRoundedIcon />
            </ListItemDecorator>
            Kwaliteit
          </Tab>
          <Tab variant="plain" color="neutral" value={'insights'}>
            <ListItemDecorator>
              <BubbleChartRoundedIcon />
            </ListItemDecorator>
            Inzicht
          </Tab>
          <Tab variant="plain" color="neutral" value={'advice'}>
            <ListItemDecorator>
              <HandshakeRoundedIcon />
            </ListItemDecorator>
            Advies
          </Tab>
          <Tab variant="plain" color="neutral" value={'actions'}>
            <ListItemDecorator>
              <LibraryAddCheckRoundedIcon />
            </ListItemDecorator>
            Acties
          </Tab>
          <Tab variant="plain" color="neutral" value={'dataviz'}>
            <ListItemDecorator>
              <DashboardCustomizeRoundedIcon />
            </ListItemDecorator>
            DataViz
          </Tab>
        </TabList>

        <Box sx={{ py: '1rem' }}>
          <TabPanel value="general">
            <GeneralAdministrationInfo />
          </TabPanel>
          <TabPanel value="quality">
            <CompletenessDrilldown renderHeader={false} />
          </TabPanel>

          <TabPanel value="efficiency">
            <EfficiencyAutomationDrilldown />
          </TabPanel>
          <TabPanel value="actions">
            <AdministrationActions />
          </TabPanel>
          <TabPanel value="insights">
            <InsightsView renderHeader={false} />
          </TabPanel>
          <TabPanel value="advice">
            <KiaTransactions renderHeader={false} />
          </TabPanel>
          <TabPanel value="dataviz">
            <DataViz />
          </TabPanel>
        </Box>
      </Tabs>
    </Stack>
  );
};
