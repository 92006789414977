import { useQuery } from '@tanstack/react-query';
import {
  getPaymentTermsAutomationByAccount,
  GetPaymentTermsAutomationByAccountParams,
} from '../../services/api/paymentTerms/getJournalAutomationByAccount';
import queryTypes from '../../types/hookTypes';

export const usePaymentTermAutomationByAccount = (params: GetPaymentTermsAutomationByAccountParams) => {
  return useQuery([queryTypes.paymentTermAutomationByAccount, ...Object.values(params)], async () => {
    const res = await getPaymentTermsAutomationByAccount(params);
    return res;
  });
};
