import Stack from '@mui/joy/Stack';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import Spinner from '../../../components/spinner';
import { YearSelect } from '../../../components/yearSelect';
import { usePaymentTermAutomationByAccount } from '../../../hooks/paymentTerms/usePaymentTermAutomationByAccount';
import useFilterStore from '../../../stores/filter';
import { PaymentTermModal } from './modal';

export default function EfficiencyAutomationDrilldown() {
  const params = useParams();
  const administrationId = params.divisionId as string;
  const { year } = useFilterStore();
  const automationQuery = usePaymentTermAutomationByAccount({
    administrationId,
    year,
  });
  const [selectedAccount, setSelectedAccount] = useState<string | null>(null);
  const [modalOpen, setModalOpen] = useState(false);

  if (!automationQuery.isSuccess) {
    return <Spinner />;
  }

  const rows = automationQuery.data;

  const columns: GridColDef[] = [
    {
      field: 'accountName',
      headerName: 'Relatie',
      flex: 1,
      disableColumnMenu: true,
      type: 'string',
    },
    {
      field: 'manualPaymentTerms',
      headerName: 'Handmatige boekingen (inkoop)',
      flex: 1,
      type: 'number',
      disableColumnMenu: true,
      valueGetter: (params) => params.value.length,
    },
  ];

  return (
    <Stack direction="column" gap={3}>
      <PaymentTermModal
        open={modalOpen}
        setOpen={setModalOpen}
        title={selectedAccount ? `Handmatige boekingen voor ${selectedAccount}` : ''}
        paymentTerms={rows.find((row) => row.accountName === selectedAccount)?.manualPaymentTerms || []}
      />
      <Stack direction="row" justifyContent={'space-between'} gap={3}>
        <YearSelect />
      </Stack>

      {automationQuery.isLoading && <Spinner />}
      {automationQuery.isSuccess && (
        <DataGrid
          sx={{
            '& .MuiDataGrid-row:hover': {
              cursor: 'pointer',
            },
          }}
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
            sorting: {
              sortModel: [{ field: 'manualPaymentTerms', sort: 'desc' }],
            },
          }}
          getRowId={(row) => row.accountName}
          onRowClick={(row) => {
            setSelectedAccount(row.row.accountName);
            setModalOpen(true);
          }}
        />
      )}
    </Stack>
  );
}
