import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DoneAllRoundedIcon from '@mui/icons-material/DoneAllRounded';
import InsertDriveFileTwoToneIcon from '@mui/icons-material/InsertDriveFileTwoTone';
import { Badge, Grid, List, ListItem, ListItemContent, Tooltip } from '@mui/joy';
import Box from '@mui/joy/Box';
import Sheet from '@mui/joy/Sheet';
import Table from '@mui/joy/Table';
import { Pagination, Typography } from '@mui/material';
import clsx from 'clsx';
import { useState } from 'react';
import Spinner from '../../components/spinner';
import { useNotes } from '../../hooks/notes/useNotes';
import { useQualityByDivsion } from '../../hooks/useQualityByDivsion';
import { TransactionModal } from '../completeness-drilldown-transactions/modal';
import { Note } from './note';
import { ViewTypeRadioGroupOptions } from './viewTypeRadioGroup';

const PAGE_SIZE = 15;

const YearIcon = (props: { value: boolean; hasOverride: boolean }) => {
  const { value } = props;
  let color: 'success' | 'warning' = 'success';
  if (!value || props.hasOverride) color = 'warning';
  switch (value) {
    case true:
      return <DoneAllRoundedIcon color={color} />;
    default:
      return <CancelRoundedIcon color={color} />;
  }
};

export const AmountIndicator = ({ value, outlier, disabled }: { value: number; outlier: boolean; disabled: boolean }) => {
  // formatter for euros in dutch, rounded up, no decimals
  const formattedValue = Math.round(value).toLocaleString('nl-NL', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  return (
    <div className="flex items-center space-x-1">
      <p className={clsx(outlier && 'text-danger', disabled && 'text-neutral')}>{formattedValue}</p>
    </div>
  );
};

export const InvoiceIndicator = ({ value, outlier, disabled }: { value: number; outlier: boolean; disabled: boolean }) => {
  return (
    <div className="flex items-center space-x-1">
      <p className={clsx(outlier && 'text-danger', disabled && 'text-neutral')}>{value}</p>
    </div>
  );
};

type CellProps = {
  color: 'success' | 'warning' | 'neutral';
  icon: 'check' | 'xmark';
  disabled: boolean;
};
const CompletenessCell = (props: CellProps) => {
  const { disabled, color: colorInput, icon } = props;

  let color = colorInput;
  if (disabled) color = 'neutral';
  switch (icon) {
    case 'check':
      return <CheckRoundedIcon color={color as any} />;
    default:
      return <CloseRoundedIcon color={color as any} />;
  }
};

type Props = {
  division: string;
  viewType: ViewTypeRadioGroupOptions;
  monthStart: number;
  monthEnd: number;
  yearStart: number;
  yearEnd: number;
};

const TransactionTable = (props: Props) => {
  const { division, viewType, monthStart, yearStart, yearEnd, monthEnd } = props;
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [selectedMeasure, setSelectedMeasure] = useState('');
  const [selectedRgsCode, setSelectedRgsCode] = useState('');
  const [selectedMonth, setSelectedMonth] = useState(0);
  const [page, setPage] = useState(0);
  const notesQuery = useNotes({
    administrationIds: [division],
    year: yearStart,
  });

  const completenessQuery = useQualityByDivsion(division, yearStart, yearEnd, monthStart + 1, monthEnd + 1);

  if (!completenessQuery.isSuccess || !completenessQuery.data) {
    return <Spinner />;
  }
  const tableData = completenessQuery.data;

  return (
    <>
      <TransactionModal
        checkId={selectedMeasure}
        month={selectedMonth}
        year={yearStart}
        division={division}
        open={detailsOpen}
        setOpen={setDetailsOpen}
        rgsCode={selectedRgsCode}
      />
      <Sheet
        className="OrderTableContainer"
        variant="outlined"
        sx={{
          'width': '100%',
          'borderRadius': 'sm',
          'flexShrink': 1,
          'overflow': 'auto',
          'minHeight': 0,
          'maxHeight': '60vh',
          '& tr': {
            '&:hover': {
              cursor: 'pointer',
            },
          },
        }}
      >
        <Table aria-label="basic table" stickyHeader stickyFooter hoverRow>
          <thead>
            <tr>
              <th style={{ width: '15%' }}>Check | Volledig | Automatisering</th>
              <th style={{ width: '5%' }}></th>
              {tableData.range.map((yearMonth) => {
                // Get month label in dutch
                const month = new Date(yearMonth.year, yearMonth.month - 1).toLocaleString('nl-NL', { month: 'short' });
                return (
                  <th key={`${yearMonth.year}-${yearMonth.month}`} style={{ width: `${70 / tableData.range.length}%` }}>
                    {month}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {completenessQuery.data.rows
              .sort((a, b) => a.qualityCheckId.localeCompare(b.qualityCheckId))
              .slice(page * PAGE_SIZE, (page + 1) * PAGE_SIZE)
              .map((row) => {
                return (
                  <tr key={row.rgsCodes}>
                    <td>{row.qualityCheckId}</td>
                    <td>
                      <YearIcon value={row.complete} hasOverride={row.hasOverride} />
                    </td>
                    {tableData.range.map((yearMonth) => {
                      const data = row.rows[yearMonth.year][yearMonth.month];
                      const notes = notesQuery.data || [];
                      const notesForCell = notes.filter((note) => {
                        const sameRgsCode = note.rgsCode === row.rgsCodes;
                        const sameYear = note.financialYear === yearMonth.year;
                        const samePeriod = note.financialPeriod === yearMonth.month;
                        return sameRgsCode && sameYear && samePeriod;
                      });
                      const onClick = () => {
                        setSelectedMeasure(row.qualityCheckId);
                        setSelectedMonth(yearMonth.month - 1);
                        setDetailsOpen(true);
                        setSelectedRgsCode(row.rgsCodes);
                      };

                      // Disabled if in the future
                      const now = new Date();
                      now.setFullYear(yearMonth.year);
                      now.setMonth(yearMonth.month - 1);
                      const disabled = now > new Date();

                      let color = 'success';
                      if (viewType === ViewTypeRadioGroupOptions.transactionAmounts && data.outlier) color = 'warning';
                      if (viewType === ViewTypeRadioGroupOptions.invoiceAmounts && data.invoiceOutlier) color = 'warning';
                      if (viewType === ViewTypeRadioGroupOptions.checks && data.transactionCount === 0) color = 'warning';

                      return (
                        <td onClick={onClick} key={`${row.rgsCodes}-${yearMonth.year}-${yearMonth.month}`}>
                          <Tooltip
                            hidden={notesForCell.length === 0}
                            color="neutral"
                            variant="outlined"
                            title={
                              <List>
                                {notesForCell.map((note) => {
                                  const tooltipContent = `${new Date(Number.parseInt(note.createdAt, 10)).toLocaleDateString('nl-NL')}: ${note.text}`;
                                  return (
                                    <ListItem key={note.id}>
                                      <ListItemContent>
                                        <Typography>{tooltipContent}</Typography>
                                      </ListItemContent>
                                    </ListItem>
                                  );
                                })}
                              </List>
                            }
                          >
                            <Badge
                              badgeContent={
                                notesForCell.length ? <InsertDriveFileTwoToneIcon sx={{ fontSize: '1rem', backgroundColor: 'transparent' }} /> : null
                              }
                              sx={{ fontSize: 'sm' }}
                              color={color as any}
                              variant="plain"
                              size="sm"
                              showZero={false}
                            >
                              {viewType === ViewTypeRadioGroupOptions.transactionAmounts && (
                                <AmountIndicator outlier={data.outlier} value={data.periodTotal} disabled={disabled} />
                              )}
                              {viewType === ViewTypeRadioGroupOptions.invoiceAmounts && (
                                <InvoiceIndicator outlier={data.invoiceOutlier} value={data.invoiceCount} disabled={disabled} />
                              )}
                              {viewType === ViewTypeRadioGroupOptions.checks && (
                                <CompletenessCell
                                  color={color as any}
                                  icon={data.transactionCount > 0 || data.checkOverride ? 'check' : 'xmark'}
                                  disabled={disabled}
                                />
                              )}
                            </Badge>
                          </Tooltip>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </Sheet>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Pagination count={Math.ceil(tableData.rows.length / PAGE_SIZE)} color="primary" onChange={(_, page) => setPage(page - 1)} page={page + 1} />
      </Box>

      <Typography variant="h6">Notities</Typography>
      <Grid container spacing={3} maxHeight={'150px'}>
        {(notesQuery.data || []).map((note) => {
          return (
            <Grid xs={12} key={note.id}>
              <Note note={note} refetch={notesQuery.refetch} />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default TransactionTable;
