import { Modal, ModalDialog, Stack, Typography } from '@mui/joy';
import ModalClose from '@mui/joy/ModalClose';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { PaymentTerm } from '../../../services/api/paymentTerms/getJournalAutomationByAccount';
import { formatter } from '../../../translations/numberFormatter';

type PaymentTermModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  title: string;
  paymentTerms: PaymentTerm[];
};

export const PaymentTermModal = (props: PaymentTermModalProps) => {
  const { setOpen, open, title, paymentTerms } = props;

  const columns: GridColDef[] = [
    {
      field: 'invoiceDate',
      headerName: 'Factuurdatum',
      flex: 1,
      disableColumnMenu: true,
      type: 'string',
      valueFormatter: (params) => dayjs(new Date(params.value)).format('DD/MM/YYYY HH:mm'),
    },
    {
      field: 'entryNumber',
      headerName: 'Boeknummer',
      flex: 1,
      disableColumnMenu: true,
      type: 'string',
    },
    {
      field: 'description',
      headerName: 'Omschrijving',
      flex: 2,
      disableColumnMenu: true,
      type: 'string',
    },
    {
      field: 'transactionAmountDC',
      headerName: 'Bedrag',
      flex: 2,
      disableColumnMenu: true,
      type: 'number',
      valueFormatter: (params) => formatter.format(params.value as number),
    },
  ];

  return (
    <Modal
      aria-labelledby="modal-title"
      aria-describedby="modal-desc"
      open={open}
      onClose={() => setOpen(false)}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <ModalDialog minWidth={'75vw'}>
        <ModalClose variant="soft" color="primary" />
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Typography level="h2">{title}</Typography>
        </Stack>
        <Stack>
          <DataGrid
            rows={paymentTerms}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            getRowId={(row) => row.id}
          />
        </Stack>
      </ModalDialog>
    </Modal>
  );
};
